<template>
  <v-container class="align-center fill-height">

    <v-card>
      <h2 class="text-h4">
        You're lost
      </h2>
      404 or something like that
    </v-card>

  </v-container>
</template>

<script>
  export default {
    data: () => ({
    }),
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.container {
  width: 55rem;
  max-width: 100%;
}

.v-card {
  width: 55rem;
  padding: 20px;
  text-align: center;
  border-radius: 1.5rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}

</style>
